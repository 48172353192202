import React, { useContext, useState } from 'react'
import { Link as GatsbyLink } from 'gatsby'
import LocaleContext from '../contexts/LocaleContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faGlobe, faRegular } from '@fortawesome/free-solid-svg-icons'
import DropdownMenu from './DropdownMenu'
import Link from './Link'

class LocaleSwitcher extends React.Component {
  state = {
    dropdown: false,
  }

  toggleDropdown = () => {
      this.setState({dropdown: !this.state.dropdown})
  }

  render() {
    return (
      <LocaleContext.Consumer>
        {({ language, setLanguage }) => (
          <p onClick={() => this.toggleDropdown()}>
          <div className="menu__dropdown">
              <div className='menu__item link-simple'>
                <FontAwesomeIcon icon={faGlobe} />{ language=='sv' ? ' Swedish ' : ' English ' }  <FontAwesomeIcon className={'fa-caret-down'} icon={faCaretDown} /></div>
                  <div className={`menu__dropdownlinks ${this.state.dropdown && 'menu__dropdownlinks-mobile'}`}>
                    <ul className='list-simple'>
                      <li className={`list-dropdown dropdown-border`}>
                      <GatsbyLink to={'/'}
                        className={`margin-left-small ${language=='sv' ? 'link-inactive' : 'link-simple'}`}
                        onClick={() => { setLanguage('sv'); sessionStorage.setItem("lang", "sv"); }}>
                        Swedish
                      </GatsbyLink>
                      </li>
                      <li className={`list-dropdown`}>
                       <GatsbyLink to={'/en'}
                        className={`margin-left-small ${language=='en' ? 'link-inactive' : 'link-simple'}`}
                        onClick={() => { setLanguage('en'); sessionStorage.setItem("lang", "en"); }}>
                        English
                      </GatsbyLink>
                      </li>
                    </ul>
              </div>
          </div>
        </p>
        )}
      </LocaleContext.Consumer>
    );
  }
}

export default LocaleSwitcher