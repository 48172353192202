import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

export default class Details extends React.Component {
  state = {
    expanded: false,
  }

  handleTitleClick = () => {
    this.setState({ expanded: !this.state.expanded })
  }

  render() {
    const { title, content } = this.props

    return (
      <div className={`details ${this.state.expanded && 'details--expanded'}`}>
        <button onClick={this.handleTitleClick} className="details__header">
          <strong>{title}</strong> <FontAwesomeIcon icon={faChevronDown} />
        </button>
        <div
          className="details__content prose"
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        ></div>
      </div>
    )
  }
}
