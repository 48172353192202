import React from 'react'

export default function ShorterContent({ children }) {
  return (
    <div className="grid">
      <div className="col col--sm-0 col--md-1"></div>
      <div className="col col--md-10">{children}</div>
    </div>
  )
}
