import axios from 'axios'

export default function sendToHubspot(data) {
  const url =
    'https://api.hsforms.com/submissions/v3/integration/submit/8429492/900789a6-2063-4510-85ef-12afe874bf05/'
  const fieldsToSend = [
    'email',
    'name',
    'phone',
    'contact_me',
    'newsletter',
    'memo',
    'filled_form',
  ]

  const fields = Object.entries(data)
    .filter(([key]) => fieldsToSend.indexOf(key) !== -1)
    .map(([key, value]) => ({
      name: key,
      value,
    }))
  return axios.post(url, { fields })
}
