import React, { useContext } from 'react'
import { Link as GatsbyLink } from 'gatsby'
import LocaleContext from '../contexts/LocaleContext'
import { Location } from '@reach/router'

class InnerLink extends React.Component {
  scrollTo = anchor => {
    if (!anchor) return

    const targetTop = document.getElementById(anchor).offsetTop
    const menuHeight =
      document.getElementsByClassName('menu')[0].offsetHeight || 0

    window.scrollTo({
      top: targetTop - menuHeight,
      behavior: 'smooth',
    })

    window.history.replaceState(undefined, undefined, `#${anchor}`)
  }

  handleSamePageLinkClick = e => {
    const { to, onClick } = this.props

    e.preventDefault()

    const anchor = to.split('#')[1]
    this.scrollTo(anchor)

    if (onClick) {
      onClick(e)
    }
  }

  getLocalizedTo = () => {
    const { to, skipLocalePrefix, locale } = this.props

    if (skipLocalePrefix) {
      return to
    }
    
    if (to.startsWith('/')) {
      if (typeof locale == 'object' && locale!==null )
        return `/${locale.language}${to}`
      else return `/${locale}${to}`
    }
    else return to
  }

  isOnSamePage = () => {
    const targetPath = this.getLocalizedTo().split('#')
    return this.props.location.pathname === targetPath[0] && targetPath[1]
  }

  render() {
    const { to, children, className, onClick } = this.props

    if (to.startsWith('http')) {
      return (
        <a className={className} href={to} onClick={onClick}>
          {children}
        </a>
      )
    } else if (this.isOnSamePage() && to.includes('#')) {
      return (
        <a
          href={`#${this.isOnSamePage()}`}
          className={className}
          onClick={this.handleSamePageLinkClick}
        >
          {children}
        </a>
      )
    } else {
      return (
        <GatsbyLink
          className={className}
          to={this.getLocalizedTo()}
          onClick={onClick}
        >
          {children}
        </GatsbyLink>
      )
    }
  }
}

function Link(props) {
  var locale;
  if (typeof locale == 'object' && locale!==null ) locale = useContext(LocaleContext).language
  else locale = useContext(LocaleContext)
  return (
    <Location>
      {locationProps => (
        <InnerLink {...locationProps} {...props} locale={locale} />
      )}
    </Location>
  )
}

export default Link
