import React from 'react'
import Details from './Details'

export default function Faq({ faq }) {
  if (!faq.length) return null
  return (
    <div className="gray-background">
      <div className="container center-width prose">
        <div className="grid">
          <div className="col col--lg-6">
            {faq.slice(0, Math.ceil(faq.length / 2)).map(faq => (
              <Details title={faq.title} content={faq.content} key={faq.id} />
            ))}
          </div>
          <div className="col col--lg-6">
            {faq.slice(Math.ceil(faq.length / 2)).map(faq => (
              <Details title={faq.title} content={faq.content} key={faq.id} />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
