import React from 'react'
import Img from 'gatsby-image'
import Link from '../components/Link'
import LocaleContext from '../contexts/LocaleContext'

export default function HighlightedMembers({ members }) {
  const locale = React.useContext(LocaleContext).language
  return (
    <div className="gray-background">
      <section className="container-big center-width prose">
        {members.map(({ memberMoreInfo, highlightedMember, id, link }) => <div className="grid flex--v-center highlighted-member" key={id}>
          <div className="col col--md-4">
            <Img fluid={memberMoreInfo.photo.fluid} className="border-radius-full" />
          </div>
          <div className="col col--md-8">
            <div
              dangerouslySetInnerHTML={{
                __html: highlightedMember || '',
              }}
            ></div>
            {link && locale=="sv" && <Link to={link} skipLocalePrefix={true} className="btn margin-top-small">
              Läs mer
            </Link>}
          </div>
        </div>)}
      </section>
    </div>)

}
