import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import CookieConsent from 'react-cookie-consent'
import App from '../templates/App.jsx'

import '../styles/index.scss'

const TemplateWrapper = ({ className, children, locale }) => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        datoCmsSite {
          faviconMetaTags {
            ...GatsbyDatoCmsFaviconMetaTags
          }
        }
        datoCmsFooter {
          ...Footer
        }
      }
    `}
    render={data => (
      <React.Fragment>
        <HelmetDatoCms favicon={data.datoCmsSite.faviconMetaTags}>
          {process.env.NODE_ENV !== 'production' && (
            <meta name="robots" content="noindex" />
          )}
        </HelmetDatoCms>
        <App><div className={className || ''}>{children}</div></App>
        <CookieConsent
          containerClasses="cookie-consent container-small"
          buttonClasses="btn small margin-top-small"
          disableStyles
          buttonText={locale=="sv" ? "Bekräfta" : "I understand"}
        >
          <CookieText locale={locale} />
        </CookieConsent>
      </React.Fragment>
    )}
  />
)

const CookieText = ({locale}) => {
  const sv = "Webbsidan använder cookies för att möjliggöra en bra upplevelse för besökare. Genom att fortsätta använda webbsidan godkänner du användning av cookies. Läs mer om cookies under vår integritetspolicy.saa"
  const en = "The website uses cookies to enable a good experience for visitors. By continuing to use the website, you agree to the use of cookies. Read more about cookies under our privacy policy."
  return (
    <p>{locale == "sv" ? sv : en}</p>
  )
}

TemplateWrapper.propTypes = {
  children: PropTypes.object,
}

export default TemplateWrapper
