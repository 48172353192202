import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import Link from './Link'

export default class DropdownMenu extends React.Component {
    state = {
        dropdown: false,
    }

    toggleDropdown = () => {
        this.setState({dropdown: !this.state.dropdown})
    }

  render() {
    const { item } = this.props

    return (
        <li onClick={() => this.toggleDropdown()}>
          <div className="menu__dropdown">
              <div className='menu__item link-simple'>{item.name} <FontAwesomeIcon className={'fa-caret-down'} icon={faCaretDown} /></div>
                  <div className={`menu__dropdownlinks ${this.state.dropdown && 'menu__dropdownlinks-mobile'}`}>
                      <ul className='list-simple'>
                      {item.menu.map((link, i)=> (
                        <li className={`list-dropdown ${item.menu[i+1] && 'dropdown-border'}`}>
                          <Link className="link-simple" to={link.target} skipLocalePrefix={!link.useLocalization}>{link.name}</Link>
                      </li>
                    ))}
                  </ul>
              </div>
          </div>
        </li>
    )
  }
}
