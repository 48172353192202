import React, { useState } from 'react'
import { Formik, Field, Form } from 'formik'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import sendToHubspot from '../services/sendToHubspot'
import sendContactForm from '../services/sendContactForm'
import LocaleContext from '../contexts/LocaleContext'
import { useContext } from 'react'

function ContactUs({ data: { contactUs, contactPerson, name, title, photo, phoneNumber, email } }) {
  const locale = useContext(LocaleContext).language

  async function handleFormSubmit(values, { setSubmitting }) {
    try {
      await sendToHubspot(values)
      await sendContactForm({ form: values })
    } catch (error) {
      setStatus('error')
    } finally {
      setStatus('sent')
      setSubmitting(false)
    }
  }
//
  const [status, setStatus] = useState('initial')

  if (status === 'sending') return <div>Please wait, sending...</div>

  if (status === 'sent')
    return (
      <div>Thank you for your interest! We will contact you back soon.</div>
    )

  if (status === 'error')
    return <div>Something went wrong! Please, try again later.</div>

  return (
    <section className="margin-top-big margin-bottom-big" id="contact-us">
      <div className="grid">
        <div className="col col--md-11">
          <div
            className="prose"
            dangerouslySetInnerHTML={{ __html: contactUs }}
          />
          <Formik
            initialValues={{
              name: '',
              email: '',
              organization: '',
              phone: '',
              message: '',
              newsletter: false,
              memo: false,
              contact_me: true,
              filled_form: 'Contact Us (large)',
            }}
            onSubmit={handleFormSubmit}
          >
            {({ handleChange, handleSubmit, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <div className="grid mobile-reverse">
                  <div className="col col--md-4 ">
                    <div className="input margin-top-small">
                      <input
                        className="input__element"
                        type="email"
                        name="email"
                        id="contact_email"
                        required
                        placeholder={locale=="sv" ? 'E-mail adress' : 'E-mail address'}
                        onChange={handleChange}
                      />
                      <input
                        type="hidden"
                        name="filled_form"
                        required
                        onChange={handleChange}
                      />
                    </div>
                    <div className="input margin-top-small">
                      <input
                        className="input__element"
                        type="text"
                        name="name"
                        id="contact_name"
                        placeholder={locale=="sv" ? "Fullständigt namn (frivilligt)" : 'Full name (optional)'}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="input margin-top-small">
                      <input
                        className="input__element"
                        type="phone"
                        name="phone"
                        id="contact_phone"
                        placeholder={locale=="sv" ? "Telefonnummer (frivilligt)" : 'Phone number (optional)'}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="input margin-top-small">
                      <input
                        className="input__element"
                        type="message"
                        name="message"
                        required
                        id="contact_message"
                        placeholder={locale=="sv" ? "Meddelande" : 'Message'}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="margin-top">
                      <button
                        type="submit"
                        className="btn full-width"
                        disabled={isSubmitting}
                      >
                        {locale=="sv" ? 'Skicka' : 'Submit'}
                      </button>
                    </div>
                  </div>

                  <div className="col col--md-4 text-center">
                    <Img
                      fluid={photo.fluid}
                      className="margin-bottom-small margin-top-big inline-block"
                      style={{ width: '70%' }}
                    />
                  </div>
                  <div className="col col--md-4 text-left margin-top-big">
                    <strong className="block">{name}</strong>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: title,
                      }}
                    ></div>
                    <br />
                    <div>{locale=="sv" ? 'Telefon' : 'Telephone'}: {phoneNumber}</div>
                    <br />
                    <div>E-mail: {email}</div>
                  </div>

                  <div className="col col--md-6">
                    <Field
                      type="hidden"
                      name="memo"
                      id="memo"
                      onChange={handleChange}
                    />
                    <Field
                      type="hidden"
                      name="newsletter"
                      id="newsletter"
                      onChange={handleChange}
                    />
                    <Field
                      type="hidden"
                      name="contact_me"
                      id="contact_me"
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment Contact on DatoCmsContact {
    contactUs
    name
    title
    photo {
      fluid(maxWidth: 700) {
        ...GatsbyDatoCmsFluid
      }
    }
    phoneNumber
    email
    contactPerson {
      name
      title
      email
      phoneNumber
      introductionNode {
        childMarkdownRemark {
          html
        }
      }
      photo {
        fluid(maxWidth: 700) {
          ...GatsbyDatoCmsFluid
        }
      }
    }
  }
`

export default ContactUs
