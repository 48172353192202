import React, { useContext, useState } from 'react'
import Link from './Link'
import GatsbyImg from 'gatsby-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faBars, faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'
import Newsletter from './Newsletter'
import { graphql } from 'gatsby'
import DropdownMenu from './DropdownMenu'
import LocaleContext from '../contexts/LocaleContext'
import LocaleSwitcher from './LocaleSwitcher'

let heroHeight
class Menu extends React.Component {

  menuOverlayRef = React.createRef()
  static contextType = LocaleContext;
  state = {
    expanded: false,
    dropdown: false,
  }

  scrollListener = () => {
    if (window.scrollY <= heroHeight) {
      this.menuOverlayRef.current.style = `opacity: ${window.scrollY /
        heroHeight};`
    } else this.menuOverlayRef.current.style = 'opacity: 1;'
  }
  handleButtonClick = () => {
    this.setState({ expanded: !this.state.expanded })
  }
  componentDidMount() {
    if (this.props.transparent) {
      heroHeight = document.querySelector('.hero').scrollHeight - document.querySelector('.menu').scrollHeight
      window.addEventListener('scroll', this.scrollListener)
    }
  }
  componentWillUnmount() {
    if (this.props.transparent) {
      window.removeEventListener('scroll', this.scrollListener)
    }
  }
  toggleDropdown = () => {
    this.setState({dropdown: !this.state.dropdown})
  }
  render() {
    const {
      menu: { logo, menuItems, items, hamburgerOverlayBackground },
      newsletter,
      socialMedia
    } = this.props
    const dropdownLinks = [
      {
        to: "/portfolio/portfolioHoldings",
        title: "Portföljinnehav"
      },
      {
        to: "/portfolio/assets",
        title: "Substansvärde"
      },
    ]
    return (
      <React.Fragment>

        <div
          className={`menu-overlay ${this.props.transparent &&
            'menu-overlay--transparent'}`}
          ref={this.menuOverlayRef}
        />
        <div className="menu">
          <div className="menu__mobile-top">
            <div className="flex flex--v-center">
              <Link to={this.context.language=='en' ? '/en' : '/'} skipLocalePrefix={true} className="link-simple">
                <img className="menu__logo" src={logo.url} alt="logo" />
              </Link>
              {socialMedia && socialMedia.map(link => (
                <a
                  href={link.target}
                  target="_blank"
                  rel="noreferrer"
                  className="margin-left-small icon link-simple flex flex--v-center"
                  key={link.name}
                >
                  <img src={link.iconWhite.url} alt={link.name} className="block" />
                </a>
              ))}
            </div>
            <button
              type="button"
              className="menu__button"
              onClick={this.handleButtonClick}
            >
              <FontAwesomeIcon icon={this.state.expanded ? faTimes : faBars} />
            </button>
          </div>
          <div
            className={`menu__content ${(this.state.expanded &&
              'menu__content--visible') || ''}`}
          >
            <div className="menu__background">
              <GatsbyImg
                fluid={hamburgerOverlayBackground.fluid}
                objectFit="cover"
              />
            </div>
            <nav className="menu__nav">
              <ul className="menu__links list-simple">

                {menuItems.map(item => 
                  <li>
                    {!item.menu && 
                      <Link className="link-simple menu__item" to={item.target} skipLocalePrefix={!item.useLocalization}>
                        {item.name}
                      </Link>
                    }
                    {item.menu  && <DropdownMenu item={item}></DropdownMenu>}
                  </li>
                )} 
                
              </ul>
            </nav>
            <div className="menu__newsletter margin-left-small">
              <Newsletter />
            </div>
            <div className='menu__nav'>
              <LocaleSwitcher />
            </div>
          </div>
        </div>

      </React.Fragment>
    )
  }
}
export const query = graphql`
  fragment Menu on DatoCmsNewMenu {
    logo {
      url
    }
    menuItems {
      ... on DatoCmsDropdown {
        name
        menu {
          ... on DatoCmsLink {
            name
            target
            useLocalization
          }
        }
      }
      ... on DatoCmsLink {
        name
        target
        useLocalization
      }
    }

    items {
      id
      name
      target
      useLocalization
    }
    rightCornerItems {
      id
      name
      target
    }
    hamburgerOverlayBackground {
      fluid(maxWidth: 800, imgixParams: { fm: "jpg", auto: "compress" }) {
        ...GatsbyDatoCmsFluid
      }
    }
  }
`
export default Menu