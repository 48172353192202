import React from 'react'
import Img from 'gatsby-image'
import Link from '../components/Link'
import blogpostTypes from '../../blogPageTypes.json'
import LocaleContext from '../contexts/LocaleContext'

function NewPosts({ posts }) {
  const locale = React.useContext(LocaleContext).language
  function getRecordTimestamp(record) {
    return new Date(record.date).getTime()
  }
  function sortRecordsByTime(a, b) {
    return getRecordTimestamp(b) - getRecordTimestamp(a)
  }

  const denodedPosts = posts.map(post => post.node || post)

  return (
    <div className="grid">
      {denodedPosts.sort(sortRecordsByTime).map((post) => (
        <div className="col col--md-6" key={post.id}>
          <Link
            className="post full-height link-simple"
            skipLocalePrefix
            to={`/${locale}/${blogpostTypes[post.postType].slug}/${post.slug}`}
          >
            {post.featuredImage && (
              <div className="post__image">
                <Img fluid={post.featuredImage.fluid} objectFit="cover" />
              </div>
            )}
            <div className="post__excerpt">
              <div className="unimp small margin-bottom-small">
                <small>{post.date}</small>
              </div>
              <div className="post__link">
                <h2>{post.title}</h2>
              </div>
              <p>{post.excerpt}</p>
            </div>
          </Link>
        </div>
      ))}
    </div>
  )
}

export default NewPosts
