import React from 'react'
import Img from 'gatsby-image'

function Hero({ heroBackground, className, children }) {
  return (
    <div className={`hero prose ${className}`}>
      {/* A hack to use gatsby-image as background image */}
      <Img
        fluid={heroBackground.fluid}
        style={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: -10,
        }}
      />
      <div className="center-width container">
        {children}
      </div>
    </div>
  )
}

export default Hero
