import React from 'react'
import { useContext } from 'react'
import LocaleContext from '../contexts/LocaleContext'

export default function VideoNews({ title, news, className, fullWidth }) {
  const locale = useContext(LocaleContext).language

  return (
    <div className={`prose ${className}`}>
      {title && (
        <div
          dangerouslySetInnerHTML={{
            __html: title.childMarkdownRemark.html,
          }}
          className="margin-bottom"
        ></div>
      )}
      <div className="grid video-news">
        {news.length % 2 === 1 && !fullWidth && <div className="col col--md-3" />}
        {news.map(newsItem => (
          <div className={`col flex flex--column video-news__item ${fullWidth ? '' : 'col--md-6'}`} key={newsItem.videoLink}>
            <div
              dangerouslySetInnerHTML={{
                __html: newsItem.oembedCode,
              }}
              className="video-news__container"
            ></div>
            <div
              className="small margin-top-small"
              dangerouslySetInnerHTML={{
                __html: newsItem.descriptionNode.childMarkdownRemark.html,
              }}
            ></div>
            <div className="toolstrip toolstrip--no-margin toolstrip--md small" style={{ marginTop: 'auto' }}>
              {newsItem.spotifyLink && <a
                className=" btn btn--outline full-width"
                href={newsItem.spotifyLink}
              >
                {locale=="sv" ? 'Lyssna på Spotify' : 'Listen on Spotify'}
              </a>}
              <a className={`btn full-width ${!newsItem.spotifyLink && 'btn--outline'}`} href={newsItem.videoLink}>
              {locale=="sv" ? 'Titta här' : 'Watch here'}
              </a>
            </div>
          </div>
        ))}
      </div>
    </div >
  )
}
