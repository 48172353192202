import React, { useContext } from 'react'
import { graphql } from 'gatsby'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import Img from 'gatsby-image'
import clip from 'text-clipper'

import ContactUs from '../components/ContactUs'
import Hero from '../components/Hero'
import Layout from '../components/Layout'
import Link from '../components/Link'
import NewPosts from '../components/NewPosts'
import ShorterContent from '../components/ShorterContent'
import VideoNews from '../components/VideoNews'
import Faq from '../components/Faq'
import HighlightedMembers from '../components/HighlightedMembers'
import StockInfo from '../components/StockInfo'
import Footer from '../components/Footer'
import Menu from '../components/Menu'
import LocaleContext from '../contexts/LocaleContext'
import LocaleSwitcher from '../components/LocaleSwitcher'

class App extends React.Component {
  setLanguage = language => {
    this.setState({ language })
  };

  state = {
    language: "sv",
    setLanguage: this.setLanguage
  }
  componentDidMount() {
    let stored = sessionStorage.getItem("lang")
    if(stored!=null) this.setLanguage(stored)
  }
  render() {
    const {
      children
    } = this.props
    return (
      <React.Fragment>
        <LocaleContext.Provider value={this.state}>
            {children}
        </LocaleContext.Provider>
      </React.Fragment>
    )
  }
}

export default App