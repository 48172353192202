import React, { useEffect } from 'react'
import { useFetch } from "react-async"
import LocaleContext from '../contexts/LocaleContext'

export default function StockInfo() {
  const locale = React.useContext(LocaleContext).language

  const { isPending, error, data, run } = useFetch('https://graphql.datocms.com/', {
    method: 'POST',
    body: JSON.stringify({
      query: `
    query stockInfo {
      stockInfo (locale: ${locale}){
        navText
        dayChangeText
        weekChangeText
        ytdText
        update
      }
    }
    `}),
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${process.env.GATSBY_STOCK_DATO_API_TOKEN}`,
    }
  })


  useEffect(() => {
    run()
  }, [run]);

  function formatNumber(n) {
    //console.log(typeof n)
    return (n < 0 ? "" : "+") + n.toLocaleString('se-sv', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  }
  
  if (isPending) return "Loading..."
  if (error) return <div></div>
  if (data) {
    if (!data.data.stockInfo) return <div></div>
    const stockInfo = data.data.stockInfo
    return (<div className="margin-top margin-bottom">
      <p><strong>{stockInfo.navText}</strong></p>
      <div className="grid-alias">
        <div className="col col--md-4"><strong>{stockInfo.dayChangeText}</strong></div>
        <div className="col col--md-4"><strong>{stockInfo.weekChangeText}</strong></div>
        <div className="col col--md-4"><strong>{stockInfo.ytdText}</strong></div>
      </div>
      <p className="small">*Per {(new Date(stockInfo.update)).toLocaleDateString(undefined, { dateStyle: 'long' })}</p>
    </div>
    )
  }

  return null
}
