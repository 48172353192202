import React from 'react'
import Link from '../components/Link'
import { graphql } from 'gatsby'
import Newsletter from './Newsletter'
import LocaleContext from '../contexts/LocaleContext'

function Footer({ footer }) {
  const locale = React.useContext(LocaleContext).language
  const {
    logo,
    copyright,
    socialMedia,
    linksNa,
    linksCompany,
    hongkongOffice,
    stockholmOffice,
    shanghaiOffice,
  } = footer

  return (
    <footer className="footer gray white-text">
      <div className="container center-width">
        <div className="grid small">
          <Link to="/" skipLocalePrefix={true} className="footer__logo col col--lg-2 col--sm-6">
            <img src={logo.url} alt="logo Nordic Asia" />
          </Link>
          <div className="col col--sm-6 col--lg-0"></div>
          <div className="col col--lg-3 col--md-4 col--sm-6">
            <strong className="margin-bottom-small block">Nordic Asia</strong>
            {linksNa.map(link => (
              <a href={link.target} className="block link-simple" key={link.id}>
                {link.name}
              </a>
            ))}
          </div>
          <div className="col col--lg-3 col--md-4 col--sm-6">
            <strong className="margin-bottom-small block">{locale=='en' ? 'News' : 'Nyheter'}</strong>
            {linksCompany.map(link => (
              <a href={link.target} className="block link-simple" key={link.id}>
                {link.name}
              </a>
            ))}
          </div>
          <div className="col col--lg-3 col--md-4 col--sm-6">
            <strong className="margin-bottom-small block">{locale=='en' ? 'Social media' : 'Sociala medier'}</strong>
            {socialMedia.map(link => (
              <a href={link.target} className="block link-simple" key={link.id}>
                {link.name}
              </a>
            ))}
          </div>
        </div>
        <hr className="margin-top margin-bottom" />
        <div className="grid small">
          <div className="col col--sm-6 col--lg-4">
          <strong className="margin-bottom-small block">Hong Kong</strong>
            <div
              dangerouslySetInnerHTML={{
                __html: hongkongOffice,
              }}></div>
          </div>
          <div className="col col--md-6 col--lg-4">
            <strong className="margin-bottom-small block">Stockholm</strong>
            <div
              dangerouslySetInnerHTML={{
                __html: stockholmOffice,
              }}></div>
          </div>
          <div className="col col--md-6 col--lg-4">
            <strong className="margin-bottom-small block">Shanghai</strong>
            <div
              dangerouslySetInnerHTML={{
                __html: shanghaiOffice,
              }}></div>
          </div>
          <div className="col col--lg-6 footer-newsletter">
            <strong className="margin-bottom-small block">
              {locale=="sv" ? 'Registrera dig för nyhetsbrev' : 'Register for our newsletter'}</strong>
            <div>
              <Newsletter />
            </div>
            <div
              className="margin-top-small"
              dangerouslySetInnerHTML={{
                __html: copyright,
              }}
            ></div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export const query = graphql`
  fragment Footer on DatoCmsFooter {
    logo {
      url
    }
    socialMedia {
      name
      target
      icon {
        url
      }
      iconWhite {
        url
      }
      id
    }
    hongkongOffice
    stockholmOffice
    shanghaiOffice
    copyright
    linksNa {
      name
      target
      id
    }
    linksCompany  {
      name
      target
      id
    }
    cookieText
  }
`

export default Footer
