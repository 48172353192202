import React, { useState } from 'react'
import { Formik, Field, Form } from 'formik'
import sendToHubspot from '../services/sendToHubspot'
import { StaticQuery, graphql } from "gatsby"
import LocaleContext from '../contexts/LocaleContext'

function NewsletterInternal(_props, { datoCmsNewMenu }) {
  const locale = React.useContext(LocaleContext).language
  async function handleFormSubmit(values, { setSubmitting }) {
    try {
      setStatus('sending')
      await sendToHubspot(values)
    } catch (error) {
      setStatus('error')
    } finally {
      setStatus('sent')
      setSubmitting(false)
    }
  }

  const [status, setStatus] = useState('initial')

  if (status === 'sending') return <div>Please wait, sending...</div>

  if (status === 'sent')
    return <div>Thank you! You have been added to the mailing list.</div>

  if (status === 'error')
    return <div>Something went wrong! Please, try again later.</div>

  return (
    <div className="">
      <Formik
        initialValues={{
          name: '',
          email: '',
          organization: '',
          phone: '',
          message: '',
          newsletter: true,
          memo: false,
          contact_me: false,
          filled_form: 'Newsletter',
        }}
        onSubmit={handleFormSubmit}
      >
        {({ handleChange, handleSubmit, isSubmitting }) => (
          <div className="policy-popup">
            <Form onSubmit={handleSubmit} className="inline-form policy-popup__form">
              <Field
                type="email"
                name="email"
                placeholder={locale=="sv" ? "Fyll i din e-mail här…" : "Enter your e-mail here…"}
                required
                onChange={handleChange}
              />
              <Field
                type="hidden"
                name="filled_form"
                required
                onChange={handleChange}
              />
              <button
                type="submit"
                className="btn inline-form__button"
                disabled={isSubmitting}
              >
                {locale=="sv" ? 'Nyhetsbrev' : 'Newsletter'}
              </button>
            </Form>
            <div className="policy-popup__content">
              {locale=="sv" ? datoCmsNewMenu.newsletterAcceptance : 'By continuing, you agree to our privacy policy.'}</div>
          </div>
        )}
      </Formik>
    </div>
  )
}

export default function Newsletter(props) {
  return <StaticQuery
    query={graphql`
        query {
          datoCmsNewMenu {
            newsletterAcceptance
          }
        }
      `}
    render={(data) => NewsletterInternal(props, data)}
  />
}
