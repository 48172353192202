import axios from 'axios'

export default function sendContactForm({ form }) {
  var message = "A submission was recently made through the contact form at www.nordicasiagroup.com.\n\n"
  message += "E-mail: " + form.email + "\n\n"
  if(form.name){ message += "Name: " + form.name + "\n\n" }
  if(form.phone){ message += "Phone number: " + form.phone + "\n\n" }
  message += "Message: " + form.message + "\n\nReply to this e-mail to answer the sender."

  return axios.post('/.netlify/functions/contactUsMail', {
      email: form.email,
      subject: 'Contact form submission',
      text: message,
    })
}
